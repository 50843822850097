import React from 'react';
import KidsHeroWrap from "./style/pink";
import Container from "../../components/paper/container";
import SectionTitle from "../../components/heading/section";
import P1 from "../../components/paragraph";

const KidsHero = () => {
    return (
        <KidsHeroWrap>
            <Container>
                <SectionTitle>
                   
                </SectionTitle>
                <h4>Sexual Abuse Coverup of Arianna</h4>
                <P1>
                    Arianna's mother is covering up sexual abuse of Arianna by the mother's father, Ganraj Kumar. Arianna's mother is protecting her pedophile father by falsely alleging domestic violence against Arianna's father. Hence shifting the narrative from sexual abuse of a minor by a pedophile to falesly accusing an innocent man of domestic violence in order to keep her pedophile father out of prison to secure her inheritance.  
                </P1>
            </Container>
        </KidsHeroWrap>
    );
};

export default KidsHero;