import styled from "styled-components";

const Copyright = styled.p`
  color: #555555;
  font-weight: 500;
  font-size: 15px;
  margin: auto;
`

export default Copyright
