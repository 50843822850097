import styled from "styled-components";

const HomeSubtitle = styled.p`
  color: rgb(255, 255, 255);
  font-size: 20px;
  text-align: center;
  margin: 0;
  line-height: 1;
`

export default HomeSubtitle
