import styled from "styled-components";

const CardTitle = styled.h4`
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 12px;
  line-height: 1.3;
`

export default CardTitle