import React from 'react';
import VideoPlayer from "../../../../components/player";
import CauseListWrap from "../style/wrap";
import Container from "../../../../components/paper/container";
import {Col, Row, Space} from "antd";
import P1 from "../../../../components/paragraph";

const CauseList = () => {
    return (
        <CauseListWrap>
            <Container>
                <Space direction='vertical' size={24}>
                    <Row gutter={[20, 20]} style={{textAlign: 'center'}}>
                        <Col md={{span: 3}} span={24}>
                            <a href="channel/abolishfamilycourt">
                            <img src="https://iboycott.org/static/media/chart.9941d764c5ee6c161862.png" style={{padding: 5}}></img>
                            <p>Abolish Family Court</p>
                            </a>
                        </Col>
                        <Col md={{span: 3}} span={24}>
                            
                        </Col>
                        <Col md={{span: 3}} span={24}>
                            
                        </Col>
                        <Col md={{span: 3}} span={24}>
                            
                        </Col>
                        <Col md={{span: 3}} span={24}>
                                 
                        </Col>
                        <Col md={{span: 3}} span={24}>
                            
                        </Col>
                        <Col md={{span: 3}} span={24}>
                            
                        </Col>
                        <Col md={{span: 3}} span={24}>
                         
                        </Col>
                    </Row>
                    <div>
                        <P1>
                            
                        </P1>   
                    </div>
                </Space>
            </Container>
        </CauseListWrap>
    );
};

export default CauseList;
