import styled from "styled-components";

const HomeSlogan = styled.h1`
text-align: center;
color: rgb(255, 255, 255);
font-weight: 600;
font-size: 50px;
font-family: sans-serif;
margin-top: 32px;
line-height: 1.6;
`

export default HomeSlogan
