import React, {Fragment} from 'react';
import TopNav from "../../layouts/navs/auth-topnav";
import CriminalList from "./components/criminallist";
import DirectoryBanner from "../banners/directory";

const CriminalPage = () => {
    return (
        <Fragment>
            {/* <TopNav/> */}
            <DirectoryBanner/>
            <CriminalList/>
        </Fragment>
    );
};

export default CriminalPage;
