export const loadToken = () => {
    return localStorage.getItem("auth_token")
}

export const saveToken = (token) => {
    return localStorage.setItem("auth_token", token)
}

export const removeToken = () => {
    return localStorage.removeItem("auth_token")
}

export const loadState = () =>
{
    try
    {
        const serializedState = localStorage.getItem("state")
        if (serializedState === null)
        {
            return undefined
        }
        return JSON.parse(serializedState)
    }
    catch (err)
    {
        return undefined
    }
}

export const saveState = (state) =>
{
    try
    {
        const serializedState = JSON.stringify(state)
        localStorage.setItem("state", serializedState)
    }
    catch
    {
        console.log('Save state failed!')
    }
}
