import React, {Fragment} from 'react';
import TopNav from "../../layouts/navs/auth-topnav";
import CauseList from "./components/causelist";
import Banner4 from "../banners/banner4";

const CompanyPage = () => {
    return (
        <Fragment>
            <TopNav/>
            <Banner4/>
            <CauseList/>
        </Fragment>
    );
};

export default CompanyPage;
