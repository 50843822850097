import React, {Fragment} from 'react';
// import TopNav from "../../layouts/navs/auth-topcompany";
import CompanyList from "./components/companylist";
import BannerCo from "../banners/bannerco";

const CompanyPage = () => {
    return (
        <Fragment>
            {/* <TopNav/> */}
            <BannerCo/>
            <CompanyList/>
        </Fragment>
    );
};

export default CompanyPage;
