import React from 'react';
import VideoPlayer from "../../../../components/player";
import CompanyListWrap from "../style/wrap";
import Container from "../../../../components/paper/container";
import {Col, Row, Space} from "antd";
import P1 from "../../../../components/paragraph";

const CompanyList = () => {
    return (
        <CompanyListWrap>
            <Container>
                <Space direction='vertical' size={24}>
                    <Row gutter={[20, 20]} style={{textAlign: 'center'}}>
                        <Col md={{span: 3}} span={24}>
                            <img src="https://companiesmarketcap.com/img/company-logos/256/SBUX.webp" style={{padding: 5}}></img>
                        </Col>
                        <Col md={{span: 3}} span={24}>
                            <img src="https://companiesmarketcap.com/img/company-logos/256/MCD.webp" style={{padding: 5}}></img>
                        </Col>
                        <Col md={{span: 3}} span={24}>
                            <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/HP_logo_2012.svg/1200px-HP_logo_2012.svg.png' style={{padding: 5}}></img>
                        </Col>
                        <Col md={{span: 3}} span={24}>
                            <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/LinkedIn_Logo.svg/2560px-LinkedIn_Logo.svg.png' style={{marginTop:'50px', marginBottom:'10px', padding: 5}}></img>
                        </Col>
                        <Col md={{span: 3}} span={24}>
                            <img src='https://d1io3yog0oux5.cloudfront.net/_8ecafb628d6cc00f684f8ad31d92f036/cocacolacompany/db/784/6375/image.png' style={{marginTop:'0px', padding: 5}}></img>       
                        </Col>
                        <Col md={{span: 3}} span={24}>
                            <img src="https://yt3.googleusercontent.com/n5DRh94eycw6xGcOKTn6LKQwztTwaw24fXPniFTXA3VPgwJaiOFdBwJNtXRHYUf7OdEAk9upwH0=s900-c-k-c0x00ffffff-no-rj" style={{padding: 5}}></img>
                        </Col>
                        <Col md={{span: 3}} span={24}>
                            <img src="https://companiesmarketcap.com/img/company-logos/256/WIX.webp" style={{padding: 5}}></img>
                        </Col>
                        <Col md={{span: 3}} span={24}>
                         
                        </Col>
                    </Row>
                    <div>
                        <P1>
                            
                        </P1>   
                    </div>
                </Space>
            </Container>
        </CompanyListWrap>
    );
};

export default CompanyList;
