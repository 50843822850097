import React, {Fragment, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Container from "../../style/container";
import {Avatar, Button, Col, Divider, Drawer, Grid, Dropdown, Layout, Menu, Row} from "antd";
import LogoText from "../../style/logo-text";
import {logoutAction} from "../../redux/actions/auth";
import {useDispatch, useSelector} from "react-redux";
import {Icon} from "@iconify/react";
import axios from "axios";
import config from "../../config";
import {MenuOutlined} from "@ant-design/icons";
import HamburgerButton from "../../components/button/hamburger";
import Logo from "../../assets/img/fontbolt.png";

const {useBreakpoint} = Grid;

const { Header } = Layout

const AuthHeader = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const breakpoints = useBreakpoint();
    const [visible, setVisible] = useState(false);
    const [scroll, setScroll] = useState(0);
    const [user, setUser] = useState(null);
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)

    const navigateHandler = data => {
        console.log(data.key);
        setVisible(false);
        if (data.key !== "#arianna") {
            navigate(data.key);
        }
    };

    useEffect(() => {
        const id = window.localStorage.getItem("@ari_id");
        if (id) {
            axios
                .get(`${config.base_url}/${id}`)
                .then(resp => {
                    setUser(resp.data.user);
                })
                .catch(error => {
                    console.error(error);
                });
        }

        window.addEventListener("scroll", () => {
            setScroll(window.scrollY);
        });

        if (location.hash !== "#arianna") {
            window.scrollTo(0, 0);
        }

        return window.removeEventListener("scroll", () => {
        });
    }, [location]);

    const menuChangeHandle = (e) => {
        if(e.key === 'logout') {
            dispatch(logoutAction())
            navigate('/login')
        } else if(e.key==='myprofile') {
            navigate(`/${auth?.user?.username}`)
        } else if(e.key==='channels/abolishfamilycourt') {
            navigate(`/channels/abolishfamilycourt`)
        } else if(e.key==='/courtwatch') {
            navigate(`/courtwatch`)    
        } else if(e.key==='channels/start') {
            navigate(`channels/start`)  
        } else if(e.key==='about/legalabusesyndrome') {
            navigate(`about/legalabusesyndrome`)           
        }else {
            navigate(`/${e.key}`)
        }
    }

    const items = [
        {
            key: 'myprofile',
            icon: <Icon style={{fontSize: 18}} icon="mdi:user-outline"/>,
            label: <span style={{fontSize: 14, fontWeight: 600}}>My Profile</span>
        },
        {
            type: 'divider',
        },
        // {
        //     key: '${auth?.user?.username}/settings',
        //     label: <span style={{fontSize: 14, fontWeight: 600}}>Settings</span>
        // },
        // {
        //     type: 'divider',
        // },
        {
            key: 'logout',
            icon: <Icon style={{fontSize: 16}} icon="icon-park-outline:logout"/>,
            label: <span style={{fontSize: 14, fontWeight: 600}}>Logout</span>
        }
    ]

    const items2 = [
        // {
        //     key: 'channels/courtwatch',
        //     label: <span style={{fontSize: 14, fontWeight: 600}}>CourtWatch</span>
        // },
        // {
        //     type: 'divider',
        // },
        {
            key: 'channels/abolishfamilycourt',
            label: <span style={{fontSize: 14, fontWeight: 600}}>Family Court</span>
        },
        {
            type: 'divider',
        },
        {
            key: 'channels/legalabusesyndrome',
            label: <span style={{fontSize: 14, fontWeight: 600}}>Legal Abuse</span>
        },
        {
            type: 'divider',
        },
        {
            key: 'channel',
            label: <span style={{fontSize: 14, fontWeight: 600}}>More Channels</span>
        }
    ]

    return (
        <Header className="auth-header">
            <Container>
                <Row
                    justify="space-between"
                    align="middle"
                    style={{height: "100%", marginTop: '0px'}}>
                    <Col>
                        {/* <LogoText>
                            <Link to="/">CourtWatch</Link>
                        </LogoText> */}
                        <Link to="/"><img src={Logo} style={{height: '40px', marginTop: '0px'}} alt="Boycott"/></Link>
                    </Col>
                    <Col>
                        {breakpoints.lg ? (
                            <Row gutter={20} style={{marginTop: '7px'}}>

                                {/* <Col>
                                    {user ? (
                                        <nbsp/>
                                    ) : (
                                        <Button type="headerlink"
                                            onClick={() => {navigate("/story");}}
                                            className={location.pathname === "/story" && "active"}>
                                            Arianna's Story
                                        </Button>
                                    )}
                                </Col> */}

                                <Col>
                                    {user ? (
                                        <nbsp/>
                                    ) : (
                                        <Button type="headerlink"
                                            onClick={() => {navigate("/companies");}}
                                            className={location.pathname === "/companies" && "active"}>
                                            Companies
                                        </Button>
                                    )}
                                </Col>

                                <Col>
                                    {user ? (
                                        <nbsp/>
                                    ) : (
                                        <Button type="headerlink"
                                            onClick={() => {navigate("/causes/abolishfamilycourt");}}
                                            className={location.pathname === "/causes/abolishfamilycourt" && "active"}>
                                            Causes
                                        </Button>
                                    )}
                                </Col>

                                <Col>                           
                                    {user ? (
                                        <nbsp/>
                                    ) : (
                                        <Button type="headerlink"
                                            onClick={() => {navigate("/criminals");}}
                                            className={location.pathname === "/criminals" && "active"}>
                                            Criminals
                                        </Button>
                                    )}
                                </Col>

                                <Col>
                                    <Dropdown
                                        placement="bottomRight"
                                        menu={{
                                            items: items,
                                            onClick: menuChangeHandle,
                                        }}>
                                        <Avatar
                                            size="large"
                                            style={{
                                                fontSize: 20,
                                                cursor: "pointer",
                                                background: "#9701fc"
                                            }}
                                            src={auth?.user?.photo}>
                                            {auth?.user?.firstname?.[0]}
                                        </Avatar>
                                    </Dropdown>
                                </Col>
                            </Row>
                        ) : (
                            <Fragment>
                                <HamburgerButton
                                    type="primary"
                                    size="large"
                                    onClick={() => {setVisible(true);}}>
                                    <MenuOutlined/>
                                </HamburgerButton>
                                <Drawer
                                    visible={visible}
                                    style={{backgroundColor: '#f7ce07'}}
                                    onClose={() => {setVisible(false);}}
                                    title={
                                        // <LogoTextLight
                                        //     onClick={() => {setVisible(false);}}
                                        //     style={{color: "#9701fc!important"}}
                                        //     >
                                        //     <Link to="/">iBoycott</Link>
                                        // </LogoTextLight>
                                        <Link to="/"><img src={Logo} style={{height: '40px', marginTop: '9px'}} alt="iBoycott"/></Link>
                                    }
                                    closeIcon={<></>}
                                    >
                                    <Menu
                                        onClick={navigateHandler}
                                        style={{backgroundColor: '#f7ce07', text: '#ffffff'}}
                                        selectedKeys={[
                                            ...location.pathname.split("/"),
                                            ...location.hash.split(" "),
                                        ]}
                                        >
                                        {/* <NavHashLink to="/arianna">Arianna's Story</NavHashLink> */}
                                        <Menu.Item key='/'>Home</Menu.Item>
                                        <Menu.Item key='/channel'>Channels</Menu.Item>
                                        <Divider />
                                        <Menu.Item key='companies'>Companies</Menu.Item>
                                        <Menu.Item key="channels/abolishfamilycourt">Causes</Menu.Item>
                                        <Menu.Item key="criminals">Criminals</Menu.Item>
                                        <Menu.Item key="donate" className="btn">DONATE</Menu.Item>
                                        <Divider />
                                        {/* <Menu.Item key="/${auth?.user?.username}">Profile</Menu.Item>
                                        <Menu.Item key='logout'>Logout</Menu.Item> */}
                                        <Menu.Item key="logout">
                                            <Dropdown
                                                placement="bottomRight"
                                                menu={{
                                                    items: items,
                                                    onClick: menuChangeHandle,
                                                }}>
                                                <Avatar
                                                    size="large"
                                                    style={{
                                                        fontSize: 20,
                                                        cursor: "pointer",
                                                        background: "#9701fc"
                                                    }}
                                                    src={auth?.user?.photo}>
                                                    {auth?.user?.firstname?.[0]}
                                                </Avatar>
                                            </Dropdown>
                                        </Menu.Item>
                                    </Menu>
                                </Drawer>
                            </Fragment>
                        )}
                    </Col>
                </Row>
            </Container>
        </Header>
    );
};

export default AuthHeader;